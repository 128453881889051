import React, { useEffect, useState } from "react";
import "../styles/App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddressModal = ({ isOpen, onClose, onSubmit, type, onAddressAdded }) => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleMouseDown = (e) => {
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  const [shippingInput, setShippingInput] = useState({
    address: "",
    suburb: "",
    postcode: "",
    state: "",
    address_type: "delivery",
  });
  useEffect(() => {
    if (isOpen) {
      setShippingInput((prevInput) => ({
        ...prevInput,
        address_type: "delivery", // Set the address_type to "billing" or "delivery"
      }));
    }
  }, [isOpen, type]);

  const handleInputChange = (e) => {
    setShippingInput({
      ...shippingInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    // Validate fields
    if (
      !shippingInput.address ||
      !shippingInput.suburb ||
      !shippingInput.postcode ||
      !shippingInput.state
    ) {
      alert("All fields are mandatory!");
      return;
    }

    const data = {
      ...shippingInput,
      address_type: "delivery",
    };

    try {
      // Check user authentication
      const userResponse = await axios.get("/api/auth/user");
      const user = userResponse.data?.user;

      if (!user || !user.user_id) {
        // Redirect to login if user is not authenticated
        alert("Session expired or user not logged in. Redirecting to login.");
        navigate("/login", { replace: true }); // Use replace to prevent going back
        return;
      }

      // Make the API call to save the address
      const response = await axios.post("/api/storeAddress", data);
      onAddressAdded();

      if (response.data.status === 200) {
        alert("Address saved successfully.");
        // Reset the form and close the modal
        setShippingInput({
          address: "",
          suburb: "",
          postcode: "",
          state: "",
        });
        onClose(); // Close the modal after success
      } else if (response.data.status === 422) {
        alert("Validation error. Please check the input fields.");
      }
    } catch (error) {
      // Handle session expiration error
      if (error.response && error.response.status === 401) {
        alert("please login or register page");
        navigate("/login", { replace: true });
      } else {
        console.error("Failed to save the address:", error);
        alert("An error occurred while saving the address. Please try again.");
      }
    }
  };
  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div
        className="modal-contents"
        style={
          isMobile
            ? {
                left: "-775px",
                top: "-163px",
                position: "fixed",
                width: "90%",
                maxWidth: "400px",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              }
            : {
                left: `${position.x}px`,
                top: `${position.y}px`,
                position: "absolute",
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              }
        }
        onMouseDown={handleMouseDown}
      >
        <h3>Enter {type === "billing" ? "Billing" : "Delivery"} Address</h3>
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            name="address"
            value={shippingInput.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Suburb</label>
          <input
            type="text"
            name="suburb"
            value={shippingInput.suburb}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            type="text"
            name="state"
            value={shippingInput.state}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Postal Code</label>
          <input
            type="text"
            name="postcode"
            value={shippingInput.postcode}
            onChange={handleInputChange}
          />
        </div>
        {/* <div className="form-group">
          <label>Address Type</label>
          <input
            type="text"
            name="postcode"
            value={shippingInput.address_type}
            readOnly
          />
        </div> */}
        <div className="address-modal-buttons">
          <button
            type="button"
            onClick={handleSubmit}
            className="address-modal-submit"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={onClose}
            className="address-modal-close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
