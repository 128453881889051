import React, { useState } from "react";
import "../styles/App.css";
import { Link } from "react-router-dom";
const products = [
  {
    id: 1,
    name: "Electronics 1",
    price: 301.5,
    oldPrice: 335.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 4,
    category: "new-arrival",
  },
  {
    id: 2,
    name: "Electronics 2",
    price: 216.6,
    oldPrice: 285.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "new-arrival",
  },
  {
    id: 3,
    name: "Electronics 3",
    price: 170.0,
    oldPrice: 425.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 3,
    category: "new-arrival",
  },
  {
    id: 4,
    name: "Electronics 4",
    price: 346.5,
    oldPrice: 495.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 4,
    category: "new-arrival",
  },
  {
    id: 6,
    name: "Electronics 6",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "featured",
  },
  {
    id: 7,
    name: "Electronics 7",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "featured",
  },
  {
    id: 8,
    name: "Electronics 8",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "featured",
  },
  {
    id: 9,
    name: "Electronics 9",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "featured",
  },
  {
    id: 10,
    name: "Electronics 10",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "special",
  },
  {
    id: 11,
    name: "Electronics 11",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "special",
  },
  {
    id: 12,
    name: "Electronics 12",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "special",
  },
  {
    id: 13,
    name: "Electronics 13",
    price: 470.0,
    oldPrice: 550.0,
    image: "/assets/images/pro3/2.jpg",
    rating: 5,
    category: "special",
  },
];

const Tap = () => {
  const [activeTab, setActiveTab] = useState("new-arrival"); // State to track the active tab

  // Filter products based on the active tab
  const filteredProducts = products.filter(
    (product) => product.category === activeTab
  );

  return (
    <div className="container-fluid">
      <h2 className="section-title">Exclusive Products</h2>
      <h3 className="section-subtitle">Special Products</h3>

      {/* Tabs */}
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${
              activeTab === "new-arrival" ? "active" : ""
            }`}
            to="#new-arrival"
            onClick={() => setActiveTab("new-arrival")}
          >
            New Arrival
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === "featured" ? "active" : ""}`}
            to="#featured"
            onClick={() => setActiveTab("featured")}
          >
            Featured
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === "special" ? "active" : ""}`}
            to="#special"
            onClick={() => setActiveTab("special")}
          >
            Special
          </Link>
        </li>
      </ul>

      {/* Products */}
      <div className="row">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="col-md-3 col-sm-6 mb-4">
              <div className="product-card">
                <img
                  src={product.image}
                  alt={product.name}
                  className="img-fluid product-image"
                />  


                {/* Hover Icons */}
                <div className="hover-icons">
                  <button className="icon-btn">🛒</button> {/* Cart Icon */}
                  <button className="icon-btn">❤️</button> {/* Wishlist Icon */}
                  <button className="icon-btn">🔍</button>{" "}
                  {/* Quick View Icon */}
                  <button className="icon-btn">🔄</button> {/* Compare Icon */}
                </div>
                <div className="product-info">
                  <div className="product-rating">
                    {"★".repeat(product.rating)}
                    {"☆".repeat(5 - product.rating)}
                  </div>
                  <h5>{product.name}</h5>
                  <p className="product-price">
                    ${product.price.toFixed(2)}{" "}
                    <span className="old-price">
                      ${product.oldPrice.toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No products found in this category.</p>
        )}
      </div>
    </div>
  );
};

export default Tap;
