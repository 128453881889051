import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../styles/App.css";
import axios from "axios";
import AddressModal from "./AddressModal";
// import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const Checkout = () => {
  // const [clientId, setClientId] = useState("");
  const { items, totalQuantity } = useSelector((state) => state.cart);
  const user = useSelector((state) => {
    console.log("Redux state:", state);
    return state.auth?.user;
  });

  // const [showBillingModal, setShowBillingModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [addresses, setAddresses] = useState({ billing: [], delivery: [] });
  // const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState("");
  // const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [transferDetails, setTransferDetails] = useState("");

  // useEffect(() => {
  //   const fetchClientId = async () => {
  //     try {
  //       const response = await axios.get("/api/paypal/client-id");
  //       setClientId(response.data.clientId);
  //     } catch (error) {
  //       console.error("Failed to fetch PayPal Client ID:", error);
  //     }
  //   };
  //   fetchClientId();
  // }, []);

  useEffect(() => {
    fetchAddresses();
  }, [user]);

  // Function to fetch delivery addresses
  const fetchAddresses = async () => {
    console.log("Fetching delivery addresses...");
    try {
      const deliveryResponse = await axios.get("/api/address/delivery");

      // Check the status code and handle each case
      if (deliveryResponse.status === 200 || deliveryResponse.status === 201) {
        // If addresses were found or a default address was created
        const deliveryData = Array.isArray(deliveryResponse.data.data)
          ? deliveryResponse.data.data
          : [deliveryResponse.data.data];

        // Only set addresses if deliveryData has items, else set an empty array
        setAddresses({ delivery: deliveryData.length > 0 ? deliveryData : [] });
      } else if (deliveryResponse.status === 404) {
        console.warn(
          "No delivery addresses found. Prompting user to create one."
        );
        alert("No delivery addresses found. Please add a new address.");
        setAddresses({ delivery: [] }); // Set to empty array if no addresses
      } else if (deliveryResponse.status === 401) {
        alert("User is not authenticated. Please log in.");
      } else {
        console.error("Unexpected response status:", deliveryResponse.status);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
      // alert("An error occurred while fetching addresses.");
    }
  };

  // Refresh addresses when a new one is added
  const handleAddressAdded = (type) => {
    fetchAddresses(); // Re-fetch addresses to update dropdowns
  };

  const [total, setTotal] = useState(0);
  const [checkoutInput, setCheckoutInput] = useState({
    user_id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState([]);

  const handlePlaceOrder = async () => {
    // Check if billing and delivery addresses are selected
    if (!selectedDeliveryAddress) {
      alert("Please select delivery addresses.");
      return;
    }

    // Create a FormData object
    const orderData = new FormData();

    // Append the form fields to FormData
    orderData.append("shippingaddress", selectedDeliveryAddress);
    // orderData.append("billingaddress", selectedBillingAddress);
    orderData.append("total_price", total);
    orderData.append("payment_method", selectedPaymentMethod);

    // Append each item individually to FormData
    items.forEach((item, index) => {
      orderData.append(`items[${index}][id]`, item.id); // Use 'id' here
      orderData.append(`items[${index}][ord_price]`, item.price);
      orderData.append(`items[${index}][ord_quantity]`, item.quantity);
    });

    // Make the POST request with FormData
    try {
      const response = await axios.post("/api/place-order", orderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        console.log("Order placed successfully:", response.data);
        navigate("/thank-you");
      } else {
        throw new Error("Failed to place order.");
      }
    } catch (error) {
      console.error(
        "Error placing order:",
        error.response?.data || error.message
      );
      alert("Order placement failed. Please try again.");
    }
  };

  useEffect(() => {
    console.log("Fetching user data...");
    axios
      .get("/api/auth/user")
      .then((response) => {
        const userData = response.data.user; // Directly access user data here
        setCheckoutInput({
          user_id: userData?.user_id || "",
          firstname: userData?.firstname || "",
          lastname: userData?.lastname || "",
          email: userData?.email || "",
          phone: userData?.phone || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Calculate total price whenever items change
  useEffect(() => {
    const calculatedTotal = items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setTotal(calculatedTotal);
  }, [items]);

  // const createOrder = async (data, actions) => {
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           value: total.toFixed(2), // Use total price
  //         },
  //       },
  //     ],
  //   });
  // };
  // const onApprove = async (data, actions) => {
  //   try {
  //     const order = await actions.order.capture();
  //     console.log("Payment approved:", order);

  //     // After payment is captured, place the order in Laravel backend
  //     await placeOrderAfterPayment(order.id);

  //     alert("Payment successful and order placed!");
  //     navigate("/thank-you");
  //   } catch (error) {
  //     console.error("Error after payment approval:", error);
  //     alert("Order placement failed. Please try again.");
  //   }
  // };

  // const onError = (error) => {
  //   console.error("PayPal Checkout Error:", error);
  //   alert("Payment failed. Please try again.");
  // };

  // // Handle form submission for PayPal payment
  // const handlePayOnline = (e) => {
  //   e.preventDefault();

  //   // Ensure both addresses are selected
  //   if (!selectedBillingAddress || !selectedDeliveryAddress) {
  //     alert("Please select both billing and delivery addresses.");
  //     return;
  //   }

  //   setShowPayPalButton(true); // Show PayPal button
  // };
  return (
    <div>
      {/* Checkout Faster Button */}

      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>Check-out</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Check-out
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section-b-space">
        <div className="container">
          <div className="button-container col-lg-6 col-sm-12 col-xs-12">
            <button className="btn-fast-checkout">
              <span className="cart-icon">🛒</span> Checkout Faster
              <Link to="/login" className="btn-solid btn">
                Log in
              </Link>
            </button>
          </div>
          <div className="checkout-page">
            <div className="checkout-form">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="checkout-title">
                      <h3>Delivery Details</h3>
                    </div>
                    <div className="row check-out">
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">First Name</div>
                        <input
                          type="text"
                          name="field-name"
                          value={checkoutInput.firstname}
                          onChange={(e) =>
                            setCheckoutInput({
                              ...checkoutInput,
                              firstname: e.target.value,
                            })
                          }
                        />
                        <small className="text-danger">{error.firstname}</small>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Last Name</div>
                        <input
                          type="text"
                          name="field-name"
                          value={checkoutInput.lastname}
                          onChange={(e) =>
                            setCheckoutInput({
                              ...checkoutInput,
                              lastname: e.target.value,
                            })
                          }
                        />
                        <small className="text-danger">{error.lastname}</small>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Phone</div>
                        <input
                          type="text"
                          name="field-name"
                          value={checkoutInput.phone}
                          onChange={(e) =>
                            setCheckoutInput({
                              ...checkoutInput,
                              phone: e.target.value,
                            })
                          }
                        />
                        <small className="text-danger">{error.phone}</small>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Email Address</div>
                        <input
                          type="text"
                          name="field-name"
                          value={checkoutInput.email}
                          onChange={(e) =>
                            setCheckoutInput({
                              ...checkoutInput,
                              email: e.target.value,
                            })
                          }
                        />
                        <small className="text-danger">{error.email}</small>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">Country</div>
                        <input
                          type="text"
                          value="Australia"
                          readonly
                          disabled
                        />
                      </div>

                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <div className="field-label">Delivery Address</div>
                        <select
                          value={selectedDeliveryAddress}
                          onChange={(e) =>
                            setSelectedDeliveryAddress(e.target.value)
                          }
                        >
                          <option value="">Select Delivery Address</option>
                          {addresses.delivery.map((address) => (
                            <option key={address.id} value={address.id}>
                              {address.address}, {address.suburb},{" "}
                              {address.state}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {error.delivery_address}
                        </small>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="custom-checkbox-container">
                          <button
                            type="button"
                            className="btn-solid btn"
                            onClick={() => setShowDeliveryModal(true)} // Open modal for billing address
                          >
                            Add Delivery Address
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <div className="checkout-details">
                      <div className="order-box">
                        <div className="title-box">
                          <div>
                            Product <span>Total</span>
                          </div>
                        </div>
                        <ul className="qty">
                          {items.length > 0 ? (
                            items.map((item) => (
                              <div key={item.id}>
                                <h5>
                                  {item.name} × {item.quantity} = $
                                  {item.price * item.quantity}
                                </h5>
                              </div>
                            ))
                          ) : (
                            <p>No items in the cart.</p>
                          )}
                        </ul>

                        <ul className="total">
                          <li>
                            <h5>
                              Grand Total{" "}
                              <span className="count">${total.toFixed(2)}</span>
                            </h5>
                          </li>
                        </ul>
                      </div>

                      <div className="payment-box">
                        <h4>Payment Method</h4>
                        <div>
                          <label>
                            <input
                              type="radio"
                              value="payid"
                              checked={selectedPaymentMethod === "payid"}
                              onChange={() => {
                                setSelectedPaymentMethod("payid");
                                setTransferDetails("PayID: 0451112478");
                              }}
                            />
                            PayID
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              value="bank"
                              checked={selectedPaymentMethod === "bank"}
                              onChange={() => {
                                setSelectedPaymentMethod("bank");
                                setTransferDetails(
                                  "BSB: 062-054, ACC: 123456789, Name: COMPANY NAME"
                                );
                              }}
                            />
                            Bank Transfer
                          </label>
                        </div>
                        {selectedPaymentMethod && (
                          <div className="transfer-details">
                            <h5>Transfer Details:</h5>
                            <p>{transferDetails}</p>
                          </div>
                        )}
                        <div>
                          <input type="checkbox" id="confirm-transfer" />
                          <label htmlFor="confirm-transfer">
                            I confirm that the payment has been transferred
                          </label>
                        </div>
                        <button
                          type="button"
                          className="btn-solid btn"
                          onClick={handlePlaceOrder}
                        >
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <AddressModal
        isOpen={showBillingModal}
        onClose={() => setShowBillingModal(false)}
        type="billing"
        onAddressAdded={handleAddressAdded}
      /> */}

      <AddressModal
        isOpen={showDeliveryModal}
        onClose={() => setShowDeliveryModal(false)}
        type="delivery"
        onAddressAdded={handleAddressAdded}
      />
    </div>
  );
};

export default Checkout;
