import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// import '../src/assets/css/vendors/font-awesome.css';
// import '../src/assets/css/vendors/slick.css';
// import '../src/assets/css/vendors/slick-theme.css';
// import '../src/assets/css/vendors/animate.css';
// import '../src/assets/css/vendors/themify-icons.css';
// import '../src/assets/css/vendors/bootstrap.css';
// import '../src/assets/css/style.css';

import MainLayout from "./components/MainLayout.js";
import Login from "./auth/Login.js";
import Shop from "./pages/Shop.js";
import Register from "./auth/Register.js";
import Checkout from "./pages/Checkout.js";
import Viewcart from "./pages/Viewcart.js";
import axios from "axios";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import ProductDetail from "./pages/ProductDetail.js";
import AdminPrivateRoute from "./AdminPrivateRoute.js";
import OrderSuccess from "./pages/OrderSuccess.js";
import { jwtDecode } from "jwt-decode";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime; // Check if token has expired
  } catch (error) {
    return true;
  }
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const expiryTime = localStorage.getItem("auth_token_expiry");

    // Check if token exists and has not expired
    if (token && expiryTime && Date.now() < expiryTime) {
      setIsLoggedIn(true);
    } else {
      // If token is expired or not available, log out
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_token_expiry");
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    axios
      .post("/api/logout")
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_name");
          localStorage.removeItem("isLoggedIn");
          setIsLoggedIn(false); // Update the state correctly after logout
          // alert("Success", res.data.message, "success");
        } else {
          alert("Error", res.data.message, "error");
        }
      })
      .catch((error) => {
        alert("Error", "Failed to logout. Please try again later.", "error");
        console.error("Error logging out:", error);
      });
  };
  // const isAuthenticated = !!localStorage.getItem("auth_token");
  return (
    <Router>
      <Header isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/shop" element={<Shop />} />
        {!isLoggedIn ? (
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
        ) : (
          <Route path="/login" element={<Navigate to="/" replace />} />
        )}
        {!isLoggedIn ? (
          <Route path="/register" element={<Register />} />
        ) : (
          <Route path="/register" element={<Navigate to="/" replace />} />
        )}

        <Route path="/checkout" element={<Checkout />} />
        <Route path="/viewcart" element={<Viewcart />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/thank-you" element={<OrderSuccess />} />
        <Route
          path="/dashboard"
          element={
            isLoggedIn ? (
              <AdminPrivateRoute handleLogout={handleLogout} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
