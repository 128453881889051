import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Login({ setIsLoggedIn }) {
  const navigate = useNavigate();
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: {},
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        axios
          .post(`/api/login`, data)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("auth_token", res.data.token);
              console.log(res.data.role);
              const expirationTime = Date.now() + 30 * 60 * 1000;
              localStorage.setItem("auth_token_expiry", expirationTime);
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("user", JSON.stringify(res.data));
              setIsLoggedIn(true);
              // console.log(userData.firstname);
              // alert('Logged Successfully');
              navigate("/dashboard");
            } else if (res.status === 401) {
              alert(
                "Warning",
                "Invalid credentials. Please try again.",
                "warning"
              );
            } else {
              setLogin({ ...loginInput, error_list: res.data.errors || {} });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              console.log("401 Error response:", error.response);
              alert(
                "Warning",
                "Invalid credentials. Please try again.",
                "warning"
              );
            } else {
              console.error("Login error:", error);
              setLogin({
                ...loginInput,
                error_list: error.response?.data?.errors || {},
              });
            }
          });
      })
      .catch((error) => {
        console.error("CSRF token error:", error);
      });
  };

  return (
    <div>
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>customer's login</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">login</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="login-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Login</h3>
              <div className="theme-card">
                <form className="theme-form" onSubmit={loginSubmit}>
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      required=""
                      onChange={handleInput}
                      value={loginInput.email}
                    />
                    <span className="text-danger">
                      {loginInput.error_list.email
                        ? loginInput.error_list.email[0]
                        : ""}
                    </span>
                  </div>
                  <div className="form-group">
                    <label for="review">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="review"
                      name="password"
                      placeholder="Enter your password"
                      required=""
                      onChange={handleInput}
                      value={loginInput.password}
                    />
                    <span className="text-danger">
                      {loginInput.error_list.password
                        ? loginInput.error_list.password[0]
                        : ""}
                    </span>
                  </div>
                  <button type="submit" className="btn btn-solid">
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 right-login">
              <h3>New Customer</h3>
              <div className="theme-card authentication-right">
                <h6 className="title-font">Create A Account</h6>
                <p>
                  Sign up for a free account at our store. Registration is quick
                  and easy. It allows you to be able to order from our shop. To
                  start shopping click register.
                </p>
                <Link to="/register" className="btn btn-solid">
                  Create an Account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
