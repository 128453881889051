import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import "../styles/App.css";
import { useDispatch } from "react-redux";

const ProductDetail = () => {
  const { id } = useParams(); // Grab product ID from the URL params
  const [product, setProduct] = useState(null); // State to hold product details
  const [mainIndex, setMainIndex] = useState(0); // Carousel index
  const dispatch = useDispatch(); // Redux dispatch for adding to cart

  // Fetch product details on component mount
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        setProduct(response.data.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  // Handle Add to Cart action
  const handleAddToCart = () => {
    if (product) {
      dispatch({
        type: "ADD_TO_CART",
        payload: {
          id: product.id,
          name: product.productname,
          price: product.pro_price,
          image: product.images[0]?.imgurl,
          quantity: 1, // Default quantity
        },
      });
    }
  };

  // Handle thumbnail click for the carousel
  const handleThumbnailClick = (index) => {
    setMainIndex(index);
  };

  // Render loading if product data is not yet available
  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>Product Detail</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Product Detail
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="product-main-image">
                  <Carousel
                    activeIndex={mainIndex}
                    onSelect={handleThumbnailClick}
                    controls={true}
                    interval={null}
                  >
                    {product.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={`${axios.defaults.baseURL}public/storage/${image.imgurl}`}
                          alt={`Product Image ${index + 1}`}
                          className="main-product-image"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>

                <div className="thumb-carousel">
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={`${axios.defaults.baseURL}public/storage/${image.imgurl}`}
                      alt={`Thumbnail ${index + 1}`}
                      className={`thumbnail-product-image ${
                        mainIndex === index ? "selected-thumbnail" : ""
                      }`}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  ))}
                </div>
              </div>

              <div className="col-lg-6 rtl-text">
                <div className="product-right">
                  <h2>{product.productname}</h2>
                  <div className="rating-section">
                    <div className="rating">
                      {[...Array(5)].map((_, i) => (
                        <i key={i} className="fa fa-star"></i>
                      ))}
                    </div>
                    <h6>120 ratings</h6>
                  </div>
                  <h3 className="price-detail">${product.pro_price}</h3>

                  <div className="product-buttons">
                    <button
                      onClick={handleAddToCart}
                      className="btn btn-solid hover-solid btn-animation"
                    >
                      <i className="fa fa-shopping-cart me-1"></i> Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
