import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ViewCart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items); // Get cart items from Redux
  const totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  ); // Calculate total price

  const handleQuantityChange = (id, quantity) => {
    if (quantity > 0) {
      dispatch({ type: "UPDATE_ITEM_QUANTITY", payload: { id, quantity } });
    }
  };

  // Handle removing item from cart
  const handleRemoveItem = (id) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: id });
  };

  return (
    <div>
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>Cart</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Cart
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="cart-section section-b-space">
        <div className="container">
          {cartItems.length === 0 ? (
            <h4>Your cart is empty</h4>
          ) : (
            <>
              <div className="col-sm-12 table-responsive">
                <table className="table cart-table">
                  <thead>
                    <tr className="table-head">
                      {/* <th scope="col">Image</th> */}
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Action</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.id}>
                        {/* <td>
                          <Link to="#">
                            <img
                              src={`storage/${item.image}`}
                              alt={item.name}
                              style={{ width: "50px" }}
                            />
                          </Link>
                        </td> */}
                        <td>
                          <h5>{item.name}</h5>
                        </td>
                        <td>
                          {/* Ensure price is treated as a number */}
                          <h5>${Number(item.price).toFixed(2)}</h5>
                        </td>
                        <td>
                          <div className="qty-box">
                            <div className="input-group">
                              <input
                                type="number"
                                name="quantity"
                                className="form-control input-number"
                                value={item.quantity}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    item.id,
                                    Number(e.target.value)
                                  )
                                }
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() => handleRemoveItem(item.id)}
                            className="btn btn-danger"
                          >
                            <i className="ti-close"></i>
                          </button>
                        </td>
                        <td>
                          {/* Calculate total price per item */}
                          <h5 className="td-color">
                            ${(Number(item.price) * item.quantity).toFixed(2)}
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="table-responsive-md">
                  <table className="table cart-table">
                    <tfoot>
                      <tr>
                        <td>Total Price:</td>
                        <td>
                          <h2>${totalPrice.toFixed(2)}</h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="row cart-buttons">
                <div className="col-6">
                  <Link to="/" className="btn btn-solid">
                    Continue Shopping
                  </Link>
                </div>
                <div className="col-6">
                  <Link to="/checkout" className="btn btn-solid">
                    Checkout
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ViewCart;
