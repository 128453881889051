import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomeSlider from "./HomeSlider";
import Tap from "./Tap";

function MainLayout() {
  return (
    <div className="theme-color-1">
      <HomeSlider />
      <Tap />
    </div>
  );
}

export default MainLayout;
