import React from "react";
import { Link } from "react-router-dom";

const OrderSuccess = () => {
  return (
    <div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <div className="checkmark">
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="star"
                    height="19"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                  </svg>
                  <svg
                    className="checkmark__check"
                    height="36"
                    viewBox="0 0 48 36"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
                  </svg>
                  <svg
                    className="checkmark__background"
                    height="115"
                    viewBox="0 0 120 115"
                    width="120"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
                  </svg>
                </div>
                <h2>thank you</h2>
                <p>
                  Payment is successfully processsed and your order is on the
                  way
                </p>
                <p className="font-weight-bold">
                  Transaction ID:267676GHERT105467
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-order">
                <div className="row product-order-detail">
                  <div className="col-3">
                    <img
                      src="../assets/images/pro3/27.jpg"
                      alt=""
                      className="img-fluid blur-up lazyload"
                    />
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>product name</h4>
                      <h5>cotton shirt</h5>
                    </div>
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>quantity</h4>
                      <h5>1</h5>
                    </div>
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>price</h4>
                      <h5>$555.00</h5>
                    </div>
                  </div>
                </div>
                <div className="row product-order-detail">
                  <div className="col-3">
                    <img
                      src="../assets/images/pro3/35.jpg"
                      alt=""
                      className="img-fluid blur-up lazyload"
                    />
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>product name</h4>
                      <h5>cotton shirt</h5>
                    </div>
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>quantity</h4>
                      <h5>1</h5>
                    </div>
                  </div>
                  <div className="col-3 order_detail">
                    <div>
                      <h4>price</h4>
                      <h5>$555.00</h5>
                    </div>
                  </div>
                </div>
                <div className="total-sec">
                  <ul>
                    <li>
                      subtotal <span>$55.00</span>
                    </li>
                    <li>
                      shipping <span>$12.00</span>
                    </li>
                    <li>
                      tax(GST) <span>$10.00</span>
                    </li>
                  </ul>
                </div>
                <div className="final-total">
                  <h3>
                    total <span>$77.00</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="order-success-sec">
                <div className="row">
                  <div className="col-sm-6">
                    <h4>summery</h4>
                    <ul className="order-detail">
                      <li>order ID: 5563853658932</li>
                      <li>Order Date: October 22, 2018</li>
                      <li>Order Total: $907.28</li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <h4>shipping address</h4>
                    <ul className="order-detail">
                      <li>gerg harvell</li>
                      <li>568, suite ave.</li>
                      <li>Austrlia, 235153</li>
                      <li>Contact No. 987456321</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 payment-mode">
                    <h4>payment method</h4>
                    <p>
                      Pay on Delivery (Cash/Card). Cash on delivery (COD)
                      available. Card/Net banking acceptance subject to device
                      availability.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="delivery-sec">
                      <h3>
                        expected date of delivery: <span>october 22, 2018</span>
                      </h3>
                      <Link to="order-tracking.html">track order</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderSuccess;
