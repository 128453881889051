import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [registerInput, setRegisterInput] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    error_list: {},
  });

  const handleInput = (e) => {
    e.persist();
    setRegisterInput({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    const data = {
      firstname: registerInput.firstname,
      lastname: registerInput.lastname,
      email: registerInput.email,
      phone: registerInput.phone,
      password: registerInput.password,
      role_id: 2,
    };

    axios.get("/sanctum/csrf-cookie").then(() => {
      axios
        .post(`/api/register`, data)
        .then((res) => {
          console.log("Response data:", res.data); // Log response data

          if (res.status === 200) { // Use res.data.status instead of res.status
            localStorage.setItem("auth_token", res.data.token);
            localStorage.setItem("auth_name", res.data.name);
            alert("Register Successfully"); // Use a temporary console.log here if alert is not working
            navigate("/dashboard");
          } else {
            setRegisterInput({
              ...registerInput,
              error_list: res.data.errors || {},
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            setRegisterInput({
              ...registerInput,
              error_list: error.response.data.errors || {},
            });
            console.error("Server responded with:", error.response.data);
          } else if (error.request) {
            console.error("Request made but no response:", error.request);
          } else {
            console.error("Error setting up the request:", error.message);
          }
        });
    });
  };
  return (
    <div>
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>create account</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    create account
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="register-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>create account</h3>
              <div className="theme-card">
                <form className="theme-form" onSubmit={registerSubmit}>
                  <div className="form-row row">
                    <div className="col-md-6">
                      <label for="email">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        id="fname"
                        placeholder="First Name"
                        required=""
                        onChange={handleInput}
                        value={registerInput.firstname}
                      />
                      <span className="text-danger">
                        {registerInput.error_list.firstname
                          ? registerInput.error_list.firstname[0]
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label for="email">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        id="lname"
                        placeholder="Last Name"
                        required=""
                        onChange={handleInput}
                        value={registerInput.lastname}
                      />
                      <span className="text-danger">
                        {registerInput.error_list.lastname
                          ? registerInput.error_list.lastname[0]
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="form-row row">
                    <div className="col-md-4">
                      <label for="phone">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="phone"
                        required=""
                        onChange={handleInput}
                        value={registerInput.phone}
                      />
                      <span className="text-danger">
                        {registerInput.error_list.phone
                          ? registerInput.error_list.phone[0]
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-4">
                      <label for="email">email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required=""
                        onChange={handleInput}
                        value={registerInput.email}
                      />
                      <span className="text-danger">
                        {registerInput.error_list.email
                          ? registerInput.error_list.email[0]
                          : ""}
                      </span>
                    </div>

                    <div className="col-md-4">
                      <label for="review">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="review"
                        name="password"
                        placeholder="Enter your password"
                        required=""
                        onChange={handleInput}
                        value={registerInput.password}
                      />
                      <span className="text-danger">
                        {registerInput.error_list.password
                          ? registerInput.error_list.password[0]
                          : ""}
                      </span>
                    </div>
                    <button type="submit" className="btn btn-solid w-auto">
                      Create Account
                    </button>
                    <div className="form-group mb-3">
                      <p>
                        Already have an account? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
