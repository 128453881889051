import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../styles/App.css"; // Ensure your custom styles are properly imported

function HomeSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,  // 3 seconds per slide
    arrows: true,  // Show arrows for navigation
    responsive: [
      {
        breakpoint: 768, // For tablets and mobile devices
        settings: {
          slidesToShow: 1, // Ensure only 1 slide is shown on mobile
          slidesToScroll: 1,
          arrows: false,  // Optionally remove arrows on mobile for a cleaner look
        }
      }
    ]
  };

  return (
    <section className="p-0">
      <Slider {...settings} className="slide-1 home-slider">
        <div>
          <div className="home">
            <img
              src="../assets/images/home-banner/19.jpg"
              alt="Man Fashion"
              className="bg-img blur-up lazyload"
            />
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="slider-contain">
                    <div>
                      <h4>welcome to fashion</h4>
                      <h1>man fashion</h1>
                      <Link to="#" className="btn btn-solid">
                        shop now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="home">
            <img
              src="../assets/images/home-banner/20.jpg"
              alt="Top Collection"
              className="bg-img blur-up lazyload"
            />
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="slider-contain">
                    <div>
                      <h4>welcome to fashion</h4>
                      <h1>top collection</h1>
                      <Link to="#" className="btn btn-solid">
                        shop now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="home">
            <img
              src="../assets/images/home-banner/63.jpg"
              alt="Top Collection"
              className="bg-img blur-up lazyload"
            />
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="slider-contain">
                    <div>
                      <h4>welcome to fashion</h4>
                      <h1>top collection</h1>
                      <Link to="#" className="btn btn-solid">
                        shop now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}

export default HomeSlider;
