const initialState = {
  items: [],
  totalQuantity: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedItems;
      if (existingIndex >= 0) {
        updatedItems = [...state.items];
        updatedItems[existingIndex].quantity += 1;
      } else {
        updatedItems = [...state.items, { ...action.payload, quantity: 1 }];
      }

      return {
        ...state,
        items: updatedItems,
        totalQuantity: state.totalQuantity + 1,
      };

    case "REMOVE_FROM_CART":
      // Find the item to be removed to adjust totalQuantity correctly
      const itemToRemove = state.items.find(
        (item) => item.id === action.payload
      );

      const filteredItems = state.items.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        items: filteredItems,
        totalQuantity: state.totalQuantity - (itemToRemove?.quantity || 0), // Decrease by removed item's quantity
      };
    case "UPDATE_ITEM_QUANTITY":
      const { id, quantity } = action.payload;

      // Calculate the difference in quantity for the specific item
      const currentItem = state.items.find((item) => item.id === id);
      const quantityDifference =
        quantity - (currentItem ? currentItem.quantity : 0);

      const updatedCartItems = state.items.map((item) =>
        item.id === id ? { ...item, quantity } : item
      );

      return {
        ...state,
        items: updatedCartItems,
        totalQuantity: state.totalQuantity + quantityDifference, // Adjust total quantity by the difference
      };

    default:
      return state;
  }
};

export default cartReducer;
