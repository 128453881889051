import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "../styles/App.css";
import { useDispatch } from "react-redux";

const Shop = () => {
  const [categories, setCategories] = useState([]); // Store categories
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/api/getcategory");
        setCategories(response.data); // Set the categories
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCategories();
    fetchProducts(); // Fetch all products initially
  }, []);
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        setProduct(response.data.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);
  const handleAddToCart = (product) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        id: product.id,
        name: product.productname,
        price: product.pro_price,
        image: product.images[0]?.imgurl,
        quantity: 1, // Default quantity
      },
    });
  };

  // Fetch all products
  const fetchProducts = async () => {
    try {
      setLoading(true); // Set loading state
      const response = await axios.get("/api/products");
      setProducts(response.data); // Set the products
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch products by category using the string-based category_id (e.g., "CA001")
  const fetchProductsByCategory = async (category_id) => {
    try {
      setLoading(true); // Set loading state
      const response = await axios.get(`/api/category/${category_id}/products`);
      setProducts(response.data); // Set the products based on the selected category
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  const handleCategoryClick = (category_id) => {
    let updatedCategories = [...selectedCategories];

    // If the category is already selected, remove it
    if (updatedCategories.includes(category_id)) {
      updatedCategories = updatedCategories.filter((id) => id !== category_id);
    } else {
      // Otherwise, add the category
      updatedCategories.push(category_id);
    }

    setSelectedCategories(updatedCategories); // Update selected categories

    // If no categories are selected, fetch all products
    if (updatedCategories.length === 0) {
      fetchProducts(); // Fetch all products when no categories are selected
    } else {
      fetchProductsByCategory(updatedCategories); // Fetch products by selected categories
    }
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeSidebarAndApplyFilters = () => {
    setIsFilterOpen(false); // Close the sidebar
    if (selectedCategories.length === 0) {
      fetchProducts(); // Fetch all products if no category is selected
    } else {
      fetchProductsByCategory(selectedCategories); // Fetch products by selected categories
    }
  };
  return (
    <div>
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <h2>Collection</h2>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Collection
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section-b-space ratio_asos">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="filter-main-btn">
                  <span
                    className="filter-btn btn btn-theme"
                    onClick={toggleFilter}
                  >
                    <i className="fa fa-filter" aria-hidden="true"></i> Filter
                  </span>
                </div>
              </div>
              <div
                className="col-sm-3 collection-filter"
                style={
                  isMobile
                    ? {
                        position: "fixed",
                        top: 0,
                        left: isFilterOpen ? "0px" : "-300px",
                        width: "300px",
                        height: "100%",
                        backgroundColor: "#fff",
                        zIndex: 1000,
                        transition: "left 0.3s ease",
                        padding: "20px",
                        overflowY: "auto",
                        boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)", // Add shadow for separation
                      }
                    : {}
                }
              >
                <div className="collection-filter-block">
                  <div
                    className="mobile-back text-start"
                    onClick={closeSidebarAndApplyFilters}
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      padding: "10px 0", // Adjust padding around "Back" button
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "#333",
                      position: "relative",
                    }}
                  >
                    <i className="fa fa-angle-left pe-2" aria-hidden="true"></i>{" "}
                    BACK
                  </div>
                  <div className="collection-collapse-block open">
                    <h3 className="collapse-block-title">Category</h3>
                    <div className="collection-collapse-block-content">
                      <div className="collection-brand-filter">
                        {Array.isArray(categories) &&
                          categories.map((category) => (
                            <div key={category.category_id}>
                              <div className="form-check collection-filter-checkbox">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={category.categoryname}
                                  onClick={() =>
                                    handleCategoryClick(category.category_id)
                                  } // Use category.category_id instead of category.id
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={category.categoryname}
                                >
                                  {category.categoryname}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="collection-content col">
                <div className="page-main-content">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="top-banner-wrapper">
                        <Link to="#">
                          <img
                            src="../assets/images/mega-menu/2.jpg"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />
                        </Link>
                        <div className="top-banner-content small-section">
                          <h4>BIGGEST DEALS ON TOP BRANDS</h4>
                          <p>
                            The trick to choosing the best wear for yourself is
                            to keep in mind your body type, individual style,
                            occasion, and also the time of day or weather.
                          </p>
                        </div>
                      </div>

                      <div className="collection-product-wrapper">
                        {/* Product display logic */}
                        <div className="product-wrapper-grid">
                          <div className="row margin-res">
                            {products.map((product) => (
                              <div
                                className="col-xl-3 col-6 col-grid-box"
                                key={product.id}
                              >
                                <div className="product-box">
                                  <div className="img-wrapper">
                                    <div className="front">
                                      <Link
                                        to={`/product-detail/${product.id}`}
                                      >
                                        {/* Prepend baseURL to image URL */}
                                        {product.images &&
                                          product.images[0] && (
                                            <img
                                              src={`${axios.defaults.baseURL}public/storage/${product.images[0].imgurl}`}
                                              className="img-fluid blur-up lazyload bg-img"
                                              alt={product.productname}
                                            />
                                          )}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="product-detail">
                                    <h6>{product.productname}</h6>
                                    <p>{product.pro_description}</p>
                                    <h4>${product.pro_price}</h4>
                                  </div>
                                  <div
                                    className="cart-icon-container"
                                    onClick={() => handleAddToCart(product)}
                                  >
                                    <i className="fa fa-shopping-cart cart-icon"></i>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="product-pagination">
                          <div className="theme-paggination-block">
                            <div className="pagination">
                              {/* Pagination logic */}
                            </div>
                            <div className="pagination-info">
                              Showing Products 1-24 of 10 Results
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Shop;
