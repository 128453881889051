import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/App.css";
import { useSelector } from "react-redux";
import { HiHome } from "react-icons/hi";
function Header({ isLoggedIn }) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const cartItems = useSelector((state) => state.cart.items);
  const openNav = () => {
    setSidebarOpen(true);
  };

  const closeNav = () => {
    setSidebarOpen(false);
    setOpenSubmenu(null); // Close all submenus when the sidebar is closed
  };
  const openRightNav = () => {
    setIsRightSidebarOpen(true);
  };

  const closeRightNav = () => {
    setIsRightSidebarOpen(false);
  };

  const toggleSubmenu = (menu, event) => {
    event.preventDefault(); // Prevent default link behavior
    if (openSubmenu === menu) {
      setOpenSubmenu(null); // Close the submenu if it's already open
    } else {
      setOpenSubmenu(menu); // Open the selected submenu
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header>
      <div className="mobile-fix-option"></div>
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  <li>Welcome to Our store Multikart</li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>Call Us:
                    123 - 456 - 7890
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <ul className="header-dropdown">
                <li className="mobile-wishlist">
                  <Link to="#">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </Link>
                </li>
                {isLoggedIn ? (
                  // Show "Dashboard" link when logged in
                  <li className="onhover-dropdown mobile-account">
                    <Link
                      to="/dashboard"
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    >
                      <HiHome />
                    </Link>
                  </li>
                ) : (
                  // Show "My Account" with Login/Register when not logged in
                  <li className="onhover-dropdown mobile-account">
                    <i className="fa fa-user" aria-hidden="true"></i> My Account
                    <ul className="onhover-show-div">
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="main-menu">
              <div className="menu-left">
                <div className="navbar">
                  {/* Button to open sidebar */}
                  <Link to="#" onClick={openNav}>
                    <div className="bar-style">
                      <i
                        className="fa fa-bars sidebar-bar"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Link>

                  {/* Sidebar */}
                  <div
                    id="mySidenav"
                    className={`sidenav ${isSidebarOpen ? "open-side" : ""}`}
                  >
                    <Link
                      to="#"
                      className="sidebar-overlay"
                      onClick={closeNav}
                    ></Link>
                    <nav>
                      <div onClick={closeNav}>
                        <div className="sidebar-back text-start">
                          <i
                            className="fa fa-angle-left pe-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Back
                        </div>
                      </div>
                      <ul id="sub-menu" className="sm pixelstrap sm-vertical">
                        <li>
                          <Link
                            to="#"
                            className="has-submenu"
                            onClick={(e) => toggleSubmenu("clothing", e)}
                          >
                            Clothing <span className="sub-arrow"></span>
                          </Link>
                          <ul
                            className={`mega-menu clothing-menu ${
                              openSubmenu === "clothing" ? "visible" : "hidden"
                            }`}
                          >
                            <li>
                              <Link to="#">Women's Fashion</Link>
                            </li>
                            <li>
                              <Link to="#">Men's Fashion</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="has-submenu"
                            onClick={(e) => toggleSubmenu("bags", e)}
                          >
                            Bags <span className="sub-arrow"></span>
                          </Link>
                          <ul
                            className={`mega-menu bags-menu ${
                              openSubmenu === "bags" ? "visible" : "hidden"
                            }`}
                          >
                            <li>
                              <Link to="#">Shopper Bags</Link>
                            </li>
                            <li>
                              <Link to="#">Laptop Bags</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="brand-logo">
                  <Link to="index.html">
                    <img
                      src="../assets/images/icon/logo.png"
                      className="img-fluid blur-up lazyloaded"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="menu-right pull-right">
                <div>
                  <nav id="main-nav">
                    <Link to="#" onClick={openRightNav}>
                      <div className="toggle-nav">
                        <i className="fa fa-bars sidebar-bar"></i>
                      </div>
                    </Link>
                    <ul
                      id="main-menu"
                      className="sm pixelstrap sm-horizontal"
                      style={
                        isMobile
                          ? {
                              right: isRightSidebarOpen ? "0px" : "-410px",
                              position: "fixed",
                              top: "0",
                              width: "300px",
                              height: "100%",
                              backgroundColor: "white",
                              transition: "right 0.3s ease",
                              zIndex: "1000",
                            }
                          : {}
                      }
                    >
                      <li>
                        <div>
                          <div
                            className="mobile-back text-end"
                            onClick={() => closeRightNav()}
                          >
                            Back
                            <i
                              className="fa fa-angle-right ps-2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Link to="/" onClick={closeRightNav}>
                          Home
                        </Link>
                      </li>

                      <li>
                        <Link to="/shop" onClick={closeRightNav}>
                          Shop
                        </Link>
                      </li>
                      <li>
                        <Link to="/product" onClick={closeRightNav}>
                          Product
                        </Link>
                      </li>
                      <li>
                        <Link to="/pages" onClick={closeRightNav}>
                          Pages
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" onClick={closeRightNav}>
                          Blog
                        </Link>
                      </li>
                      {/* Add additional menu items here */}
                    </ul>
                  </nav>
                </div>

                <div>
                  <div className="icon-nav">
                    <ul>
                      <li className="onhover-div mobile-search">
                        <div>
                          <img
                            src="../assets/images/icon/search.png"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i className="ti-search"></i>
                        </div>
                        <div id="search-overlay" className="search-overlay">
                          <div>
                            {" "}
                            <span className="closebtn" title="Close Overlay">
                              ×
                            </span>
                            <div className="overlay-content">
                              <div className="container">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <form>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputPassword1"
                                          placeholder="Search a Product"
                                        />
                                      </div>
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        <i className="fa fa-search"></i>
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="onhover-div mobile-setting">
                        <div>
                          <img
                            src="../assets/images/icon/setting.png"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i className="ti-settings"></i>
                        </div>
                        <div className="show-div setting">
                          <h6>language</h6>
                          <ul>
                            <li>
                              <Link to="#">english</Link>
                            </li>
                            <li>
                              <Link to="#">french</Link>
                            </li>
                          </ul>
                          <h6>currency</h6>
                          <ul className="list-inline">
                            <li>
                              <Link to="#">euro</Link>
                            </li>
                            <li>
                              <Link to="#">rupees</Link>
                            </li>
                            <li>
                              <Link to="#">pound</Link>
                            </li>
                            <li>
                              <Link to="#">doller</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="onhover-div mobile-cart">
                        <div>
                          <img
                            src="../assets/images/icon/cart.png"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i className="ti-shopping-cart"></i>
                        </div>
                        <span className="cart_qty_cls">{totalQuantity}</span>
                        <ul className="show-div shopping-cart">
                          {cartItems.map((item, index) => (
                            <li key={index}>
                              <div className="media">
                                <img
                                  alt=""
                                  className="me-3"
                                  src={`storage/${item.image}`}
                                />
                                <div className="media-body">
                                  <h4>{item.name}</h4>
                                  <h4>
                                    <h4>
                                      <span>{`${item.quantity} x $${item.price}`}</span>
                                    </h4>
                                  </h4>
                                </div>
                              </div>
                            </li>
                          ))}
                          <li>
                            <div className="buttons">
                              <Link to="/viewcart" className="view-cart">
                                view cart
                              </Link>{" "}
                              <Link to="/checkout" className="checkout">
                                checkout
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
